import Modal from 'react-bootstrap/Modal';
import { HotTable } from '@handsontable/react';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useAuth } from 'react-oidc-context';
import { Form, ButtonGroup, ToggleButton, Button } from 'react-bootstrap'; 

import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';


import styles from '../Gayatri.module.css'
import SessionExpiredModal from '../../../utils/SessionExpiredModal';
import { Autocomplete, FormControl, MenuItem, Select, TextField } from '@mui/material';
import { SnackbarContext } from '../../../App';


const currentDate = new Date();
const currentYear = currentDate.getFullYear();


export default ({show, onHide, onRefresh}) => {

    const navigate = useNavigate()
    const auth = useAuth()

    const [isSubmitting, setIsSubmitting] = useState(false)

    const [selectedTemplate, setSelectedTemplate] = useState()

    

    // date selector for create workpaper
    const [exactDate, setExactDate] = useState(true);
    const [financialYear, setFinancialYear] = useState(false);
    const [selectFY, setSelectFY] = useState(false);
    const [financialYears, setFinancialYears] = useState([]);

    // submit popup
    const handleReviewDetailsShow = () => setReviewDetailsShow(true);
    const handleReviewDetailsClose = () => setReviewDetailsShow(false);

    const { snackBarShow, setSnackBarShow, snackBarMessage, setSnackBarMessage } = useContext(SnackbarContext);

    const [reviewDetailsShow, setReviewDetailsShow] = useState(false);
    const [clientId, setClientId] = useState();
    const [abn, setAbn] = useState('');
    const [clientName, setClientName] = useState('');
    const [alternativeName, setAlternativeName] = useState('');
    const [fromDate, setFromDate] = useState(`${currentYear-1}-07-01`);
    const [toDate, setToDate] = useState(`${currentYear}-06-30`);
    const [financialYearValue, setFinancialYearValue] = useState(currentYear);
    const [selectedFile, setSelectedFile] = useState(null);

    const [sessionExpired, setSessionExpired] = useState(false)

    const [clients, setClients] = useState([]);

    const [availableTemplates, setAvailableTemplates] = useState([])


    const fetchData = () => {
        if (auth.user?.profile.accountant.ssid){
            console.log('execute')
            fetch(`${window.origin}/api/creator/template`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${auth.user?.access_token}`
                }
            }).then(response => {
                if(response.status === 403){
                    setSessionExpired(true)
                    throw new Error('Session expired');
                }
                return response.json();
            }).then(data => {
                setAvailableTemplates(data)
                setSelectedTemplate(data[0]?.id)
            }).catch((error) => {
                console.error(error);
            });
        }
    }

    const handleClientSelect = (client) => {
        console.log(client.id);
        setClientId(client.id); // Set clientId when a client is selected
        setAbn(client.ABN);
        setClientName(client.companyName);
        setAlternativeName(client.alternativeName);
    
        // Only update date fields if they are currently empty
        if (!fromDate) {
            setFromDate('');
        }
        if (!toDate) {
            setToDate('');
        }
        if (!financialYearValue) {
            setFinancialYearValue('');
        }
        setSelectedFile(null);
    };

    useEffect(() => {
        
        fetchData()

        fetch(`${window.origin}/api/creator/clients/active`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth.user?.access_token}`
          },
        })

        .then((response) => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }
            return response.json();
        })
            .then(data => {
                console.log('Fetched Clients:', data); // Console log the fetched data
                setClients(data.map(client => ({
                    id: client.id, // Mapping client_id to id
                    ABN: client.client_abn,
                    companyName: client.client_name,
                    alternativeName: client.alt_client_name,
                })))
            })
            .catch(error => console.error('Error fetching clients:', error));

        fetch(`${window.origin}/api/creator/financial-years`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`
            },
        })
        .then((response) => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }
            return response.json();
        })
        .then(data => {
            console.log('Fetched Financial Years:', data);
            setFinancialYears(data.financial_years || []);
        })
        .catch(error => console.error('Error fetching financial years:', error));
    }, [auth.user?.access_token]);

    const handleSubmit = async () => {
        setIsSubmitting(true);
        // if (exactDate) {
        //     try {
        //         const response = await fetch(`${window.origin}/api/creator/financial-years`, {
        //             method: 'GET',
        //             headers: {
        //                 Authorization: `Bearer ${auth.user?.access_token}`
        //             }
        //         });
        //         const data = await response.json();
        //         const financialYears = data.financial_years;
        //         const selectedYear = fromDate.slice(0, 4);
        //         console.log(!String(financialYears).includes(String(selectedYear)))

        //         if (!String(financialYears).includes(String(selectedYear))) {
        //             alert(`We don't have a workpaper template for the Financial Year ${selectedYear}. Please contact specia support!`);
        //             setIsSubmitting(false);
        //             return;
        //         }
        //     } catch (error) {
        //         console.error('Error checking financial years:', error);
        //         alert("An error occurred while checking the financial years.");
        //         setIsSubmitting(false);
        //         return;
        //     }
        // }

        if(!clientId){
            setSnackBarMessage('Please select a client to create workpaper')
            setSnackBarShow(true)
            return
        }

        if(!selectedTemplate){
            setSnackBarMessage('Please select a template to create workpaper')
            setSnackBarShow(true)
            return
        }




        
        
        const formData = new FormData();
        console.log(financialYearValue)
        formData.append('client_id', clientId); // Assuming you have clientId in your component state or props
        formData.append('start_date', exactDate ? fromDate : `${Number(financialYearValue)-1}-07-01`);
        formData.append('end_date', exactDate ? toDate : `${Number(financialYearValue)}-06-30`);

        formData.append('template_id', selectedTemplate);

        // If 'prev_workpaper' corresponds to the 'selectedFile', append it.
        if (selectedFile) formData.append('file', selectedFile);

        console.log(exactDate ? fromDate : `${financialYearValue}-07-01`)
        console.log(exactDate ? toDate : `${Number(financialYearValue) + 1}-06-30`)
        fetch(`${window.origin}/api/creator/requests`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`
            },
            method: 'POST',
            body: formData,
        })
        .then((response) => {
            if(response.status === 403){
                setSessionExpired(true)
                throw new Error('Session expired');
            }
            return response.json();
        })
        .then(data => {
            console.log('Success:', data);
            handleReviewDetailsClose()
            onRefresh()
            setIsSubmitting(false)
            onHide()
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    };

    const glassStyle = {
        background: 'rgba(255, 255, 255, 0.1)', 
        backdropFilter: 'blur(10px)',
        borderRadius: '5px',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        boxShadow: '4px 4px 15px rgba(0, 0, 0, 0.1)',
        padding: '20px',
    };
        
    const noBorderStyle = {
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    };    

    const footerStyle = {
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderTop:'1px solid rgba(255, 255, 255, 0.2)'
    }
    
    const updatePeriod = (dates) => {
      const [start, end] = dates;
      setFromDate(dayjs(start).format('YYYY-MM-DD'));
      setToDate(dayjs(end).format('YYYY-MM-DD'));
    };

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
    });

   





    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName="modal-50w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            
            <Modal.Body>
                <h4 style={{textAlign: 'center', fontSize: '1.3vw', margin: '1vw', fontWeight: '600'}}>Create Workpaper</h4>

                <Form style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>

                    {/* period */}
                    <Form.Group className="mb-Period" controlId="exampleForm.Period" style={{ margin: '1vw 0vw', height: '50%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {/* <Form.Label style={{ fontSize: '1.3vw', fontWeight: '600', marginBottom:'1.5vw' }}>Period</Form.Label> */}
                            <Form.Label style={{ fontSize: '1vw', fontWeight: '600', color: 'rgba(255, 255, 255, 0.8)', marginBottom:'1vw', marginLeft: '0.5vw' }}>Period</Form.Label>
                        </div>

                        <div style={{backgroundColor: 'rgb(0, 0, 0, 0.25)', padding: '1vw', borderRadius: '0.8vw'}}>
        
                            <div style={{ display: 'flex', flexDirection: 'column', width:'25vw' }}>
                                <ButtonGroup className="btn-group-toggle" style={{ marginBottom: '1.7vw' }}>
                                    <ToggleButton
                                        key={1}
                                        id={`radio-1`}
                                        type="radio"
                                        variant='outline-light'
                                        name="radio"
                                        value='ED'
                                        onChange={() => { setExactDate(true); setFinancialYear(false); setSelectFY(false);}}
                                        style={{
                                            borderTopLeftRadius: '0.2vw',
                                            borderBottomLeftRadius: '0.2vw',
                                            fontSize: '0.8vw',
                                            padding: '0.5vw 0.7vw',
                                            backgroundColor: exactDate ? 'white' : '',
                                            borderColor: exactDate ? 'white' : '',
                                            color: exactDate ? 'black' : '', // Text color for active button
                                        }}                                            
                                    >
                                        Custom Date
                                    </ToggleButton>
                                    <ToggleButton
                                        key={2}
                                        id={`radio-2`}
                                        type="radio"
                                        variant='outline-light'
                                        name="radio"
                                        value='FY'
                                        onChange={() => { setFinancialYear(true); setExactDate(false); setSelectFY(false);}}
                                        style={{
                                            borderTopRightRadius: '0.2vw',
                                            borderBottomRightRadius: '0.2vw',
                                            fontSize: '0.8vw',
                                            padding: '0.5vw 0.7vw',
                                            backgroundColor: financialYear ? 'white' : '',
                                            borderColor: financialYear ? 'white' : '',
                                            color: financialYear ? 'black' : '', // Text color for active button
                                        }}                                            
                                    >
                                        Financial Year
                                    </ToggleButton>
                                    {/* <ToggleButton
                                        id={`radio-3`}
                                        type="radio"
                                        variant='outline-light'
                                        name="radio"
                                        value='SFY'
                                        onChange={() => {  setSelectFY(true); setExactDate(false); setFinancialYear(false);}}
                                        style={{
                                            borderTopRightRadius: '0.2vw',
                                            borderBottomRightRadius: '0.2vw',
                                            fontSize: '0.8vw',
                                            padding: '0.5vw 0.7vw',
                                            backgroundColor: selectFY ? 'white' : '',
                                            borderColor: selectFY ? 'white' : '',
                                            color: selectFY ? 'black' : '',
                                        }}
                                    >
                                        Select FY
                                    </ToggleButton> */}
                                </ButtonGroup>
                            </div>
            
                            {exactDate && (
                                <ThemeProvider theme={darkTheme}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DateRangePicker']}>
                                            <DateRangePicker
                                                format="YYYY-MM-DD"
                                                value={[dayjs(fromDate), dayjs(toDate)]}
                                                onChange={updatePeriod}
                                                startText="Start"
                                                endText="End"
                                                renderInput={(startProps, endProps) => (
                                                <>
                                                    <Form.Control
                                                    {...startProps}
                                                    style={{
                                                        width: '10vw',
                                                        margin: '0vw auto 0vw 0vw',
                                                        color: 'white',
                                                        backgroundColor: 'rgba(255, 255, 255, 0.03)',
                                                        border: '0.5vw',
                                                        padding: '1vw',
                                                        fontSize:'1vw'
                                                    }}
                                                    />
                                                    {/* <Form.Label style={{ fontSize: '1.5vw' }}>-</Form.Label> */}
                                                    <Form.Control
                                                    {...endProps}
                                                    style={{
                                                        width: '10vw',
                                                        margin: '0vw 0vw 0vw auto',
                                                        color: 'white',
                                                        backgroundColor: 'rgba(255, 255, 255, 0.03)',
                                                        border: 'none',
                                                        padding: '1vw',
                                                        fontSize:'1vw'
                                                    }}
                                                    />
                                                </>
                                                )}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </ThemeProvider>
                            )}
            
                            {financialYear && (
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingTop:'8px'}}>
                                    {/* Year */}
                                    <Form.Select
                                        style={{ fontSize:'1vw', fontWeight:'600', width: '100%', margin: '0vw auto', color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.03)', border: 'none', padding: '1vw' }}
                                        value={financialYearValue}
                                        onChange={(e) => setFinancialYearValue(e.target.value)}
                                    >
                                        {
                                        [...Array(5)].map((_, index) => {
                                            let year = Number(currentYear+1) - index;
                                            return <option key={year} value={year} style={{ color: 'black' }}>{year}</option>
                                        })
                                        }
                                    </Form.Select>
                                </div>
                            )}

                            {selectFY && (
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingTop: '8px' }}>
                                    <Form.Select
                                        style={{
                                            fontSize: '1vw',
                                            fontWeight: '600',
                                            width: '100%',
                                            margin: '0vw auto',
                                            color: 'white',
                                            backgroundColor: 'rgba(255, 255, 255, 0.03)',
                                            border: 'none',
                                            padding: '1vw'
                                        }}
                                        value={financialYearValue}
                                        onChange={(e) => setFinancialYearValue(e.target.value)}
                                    >
                                        {financialYears.map((year, index) => (
                                            <option key={index} value={year} style={{ color: 'black' }}>
                                                {year}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            )}
                        </div>

                    </Form.Group>
    
                    {/* previous workpaper */}
                    <Form.Group controlId="formFile-PreviousWorkpaper" className="mb-PreviousWorkpaper" style={{ margin: '1vw 0vw', marginTop: '5vw', display: 'none'}}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Form.Label style={{ fontSize: '1.3vw', fontWeight: '600', marginBottom: '1.5vw' }}>Previous Workpaper</Form.Label>
                        <Form.Label style={{ fontSize: '0.9vw', fontWeight: '500', color: 'rgba(255, 255, 255, 0.7)', marginBottom: '1.5vw' }}>
                            (optional) Attach if referencing prior work is needed.
                        </Form.Label>
                    </div>

                        <Form.Control
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                            style={{ color: 'white', backgroundColor: 'rgba(255, 255, 255, 0.03)', border: 'none', fontSize:'1vw' }}
                        />
                    </Form.Group>


                    <Form.Group className="mb-ClientName" controlId="exampleForm.ClientName" style={{ margin: '1vw 0vw' }}>
                            {/* title */}
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                {/* <Form.Label style={{ fontSize: '1.3vw', display: 'flex', fontWeight: '600', marginBottom:'1.5vw' }}>Select Client</Form.Label> */}
                                <Form.Label style={{ fontSize: '1vw', fontWeight: '600', color: 'rgba(255, 255, 255, 0.8)', marginBottom:'1vw', marginLeft: '0.5vw' }}>Select Client</Form.Label>
                            </div>
                
                            {/* textbox */}
                            <Typeahead
                                id="client-selection-typeahead"
                                labelKey="companyName"  // Set this to 'companyName' 
                                onChange={(selected) => {
                                if (selected.length > 0) {
                                    handleClientSelect(selected[0]);
                                }
                                }}
                                options={clients}
                                placeholder="Search"
                                className="typeahead-input"
                                bsSize="large"
                                renderMenuItemChildren={(option) => (
                                <div style={{ whiteSpace: 'pre-wrap', fontSize: '1vw'}}>
                                    {`ABN: ${option.ABN}\nCompany: ${option.companyName}\nAlt Name: ${option.alternativeName}`}
                                </div>
                                )}
                                style={{ fontSize: '1vw', color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.2)', margin: '0vw', width: '100%', borderRadius: '0.6vw', border: 'none' }}
                            />

                            {/* <Autocomplete
                                    value={clients.find(item => item.id == clientId)?.companyName}
                                    onChange={(event, newValue) => {
                                        handleClientSelect(newValue[0])
                                    }}
                                    id="controllable-states-demo"
                                    options={clients}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Controllable" />}
                            /> */}
                
                            <p style={{fontSize: '0.8vw', paddingTop: '1vw', fontWeight: '400', color: 'rgb(255, 255, 255, 0.7)', paddingBottom: '0.8vw', paddingLeft: '0.3vw'}}>Couldn't find your clients? <span onClick={() => {navigate('/mydigitalworkers/gayatri/clients')}} style={{fontWeight: '500', cursor: 'pointer', color: 'white'}}>Manage your clients</span> </p>

                            {/* <div style={{overflow: 'auto', borderRadius: '0.2vw', lineHeight: '1.4vw'}}>
    
                                { !clientName && <div style={{width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row', backgroundColor: 'rgb(255, 255, 255, 0.06)' ,border: '1px solid rgb(255, 255, 255, 0.0)' , borderRadius: '0.2vw', marginBottom: '0.9vw', padding: '0.1vw 0', height: '5vw'}}>
                                    <p style={{margin: 0, color: 'rgb(255, 255, 255, 0.8)', textAlign: 'center', fontSize: '0.9vw'}}>No Client Selected</p>
                                </div>}

                                { clientName && <div style={{width: '100%', alignItems: 'center', display: 'flex', flexDirection: 'row', backgroundColor: 'rgb(255, 255, 255, 0.03)' ,border: '1px solid rgb(255, 255, 255, 0.1)' , borderRadius: '0.2vw', marginBottom: '0.9vw', padding: '0.2vw 0', height: '5vw'}}>
                                        <div style={{flex: 1}}>
                                            <div style={{paddingLeft: '1.4vw', paddingRight: '0.9vw', fontSize: '0.9vw', fontWeight: '600', color: 'white', paddingBottom: '0.3vw'}}><p className={styles.tableField} style={{backgroundColor: 'transparent', border: 0, width: '95%', color: 'white', paddingTop: '0.1.8vw', margin: 0}}>{clientName}</p></div>
                                            <div style={{paddingLeft: '1.4vw', paddingRight: '0.9vw', fontSize: '0.8vw', color: 'rgb(255, 255, 255, 0.7)'}}>{abn}</div>
                                            
                                        </div>
                                        <div>
                                            <span style={{paddingLeft: '0.9vw', paddingRight: '1.8vw', cursor: 'pointer'}}><i onClick={() => {setClientName(null)}} style={{color: 'rgb(255, 255, 255, 0.6)', fontSize: '1.2vw'}} class="fa-solid fa-xmark"></i></span>
                                        </div>
                                    </div>
                                }
                            </div> */}
                        </Form.Group>

                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                {/* <Form.Label style={{ fontSize: '1.3vw', display: 'flex', fontWeight: '600', marginBottom:'1.5vw' }}>Select Client</Form.Label> */}
                                <Form.Label style={{ fontSize: '1vw', fontWeight: '600', color: 'rgba(255, 255, 255, 0.8)', marginBottom:'1vw', marginLeft: '0.5vw' }}>Template</Form.Label>
                            </div>
                            <Select
                                style={{color: 'rgb(255, 255, 255, 0.8)', width: '100%', height: '3vw'}}
                                value={selectedTemplate}
                                onChange={(event) => {setSelectedTemplate(event.target.value)}}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                >
                                {availableTemplates.map(template => <MenuItem value={template.id}>
                                    <p style={{color: 'rgb(255, 255, 255, 0.8)', fontSize: '0.8vw', margin: 0}}>{template.name}</p>
                                </MenuItem>)}    
                                
                                
                            </Select>
                        </FormControl>

                        <div style={{textAlign: 'right', height: '8vh', flexDirection: 'row', alignItems: 'center', display: 'flex'}}>
                            <div style={{flex: 1}}>
                                <Button onClick={handleSubmit} style={{padding: '0.7vh 2vh', borderRadius: '0.4vh', fontSize: '1.5vh', marginRight: '2vh'}} variant="primary" disabled={isSubmitting}>
                                    {isSubmitting? 'Submitting': 'Next'}
                                </Button>
                            </div>
                        </div>

                </Form>


                <Modal
                show={reviewDetailsShow}
                onHide={handleReviewDetailsClose}
                centered
                contentClassName="glass-modal-content"
                style={glassStyle}
                >
                    <Modal.Header closeButton={false} style={noBorderStyle}>
                        <Modal.Title style={{ textAlign: 'center', fontWeight: '600', fontSize: '1.2em', color: 'white' }}>Review Details</Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{ color: 'white' }}>
                        <div style={{ marginBottom: '10px', fontSize: '1.1em', fontWeight: '600' }}>ABN: <span style={{ fontWeight: 'normal' }}>{abn}</span></div>
                        <div style={{ marginBottom: '10px', fontSize: '1.1em', fontWeight: '600' }}>Client Name: <span style={{ fontWeight: 'normal' }}>{clientName}</span></div>
                        <div style={{ marginBottom: '10px', fontSize: '1.1em', fontWeight: '600' }}>Alternative Name: <span style={{ fontWeight: 'normal' }}>{alternativeName}</span></div>
                        <div style={{ marginBottom: '10px', fontSize: '1.1em', fontWeight: '600' }}>
                        Choose Period:
                        {exactDate && ` From ${fromDate} To ${toDate}`}
                        {financialYear && `${financialYearValue}`}
                        </div>
                        <div style={{ fontSize: '1.1em', fontWeight: '600' }}>Previous Workpaper: <span style={{ fontWeight: 'normal' }}>{selectedFile ? selectedFile.name : "No file chosen"}</span></div>
                    </Modal.Body>

                    <Modal.Footer style={footerStyle}>
                        <p style={{ color: '#ee7170', fontWeight: '600', cursor: 'pointer' }} onClick={handleSubmit}>
                        Submit
                        </p>
                    </Modal.Footer>
                </Modal>



            </Modal.Body>
            <SessionExpiredModal show={sessionExpired}/>
        </Modal>
    )
}

